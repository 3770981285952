(function () {
    'use strict';

    function toggleMenu(elem) {
        document.addEventListener('click', function (e) {
            if (!e.target.matches(elem + ' button')) return;
            else {
                const $body = document.querySelector('body');
                let _btn = e.target.closest('button'),
                    _content = document.querySelector('header .data-wrap .main-nav');
                let scrollPosition = 0;
                if (!_btn.classList.contains('open')) {
                    scrollPosition = window.pageYOffset;
                    $body.style.overflow = 'hidden';
                    $body.style.position = 'fixed';
                    $body.style.top = `-${scrollPosition}px`;
                    $body.style.width = '100%';

                    _btn.classList.add('open');
                    _content.classList.add('open');
                } else {
                    $body.style.removeProperty('overflow');
                    $body.style.removeProperty('position');
                    $body.style.removeProperty('top');
                    $body.style.removeProperty('width');
                    window.scrollTo(0, scrollPosition);

                    _btn.classList.remove('open');
                    _content.classList.remove('open');
                }
            }
        });
    }

    toggleMenu('header .open-menu');

})();
